let sortables = {};
let index = 0;
let sortablejs = null;

let lazyLoadSortable = async () => {
    if ( ! sortablejs) {
        sortablejs = await import('sortablejs');
    }

    return true;
};

export default {
    mounted(el, binding) {
        lazyLoadSortable().then( () => {
            let options = typeof binding.value === 'object' ? binding.value : {};
            sortables[index] = sortablejs.Sortable.create(el, options);
            el.sortableId = index;
            index++;
        });

    },
    beforeUnmount(el, binding) {
        sortables[el.sortableId].destroy();
        delete(sortables[el.sortableId]);
    },
};
